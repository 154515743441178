import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Button, Card, IconButton } from '@sumup-oss/circuit-ui';
import { Grid } from '@sumup-oss/circuit-ui/legacy';
import { Shop } from '@sumup-oss/icons';
import type { FC, PropsWithChildren } from 'react';

const NOTIFICATION_SIZE = 24;
const NOTIFICATION_OFFSET = 8;
export const CART_CONTAINER_HEIGHT = '74px';

export const StyledButton = styled(Button)(
  ({ theme }) => css`
    padding: 8px 10px;

    ${theme.mq.kilo} {
      font-size: 16px;
      padding: 8px 23px;
    }

    &:disabled,
    &[aria-disabled='true'],
    &[disabled] {
      background-color: var(--cui-bg-accent-strong-disabled);
      color: var(--cui-fg-on-strong-disabled);
    }
  `,
);

export const CartWrapper = styled(Card)`
  width: 100%;
  display: block;
  background-color: var(--cui-bg-normal);
  padding: 0;
  position: fixed;
  bottom: 0;
  left: 0;
  border-radius: 0;
  z-index: var(--cui-z-index-navigation);
`;

export const CartContainer: FC<PropsWithChildren> = styled(Grid)(
  ({ theme }) => css`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    background-color: var(--cui-bg-normal);
    padding: var(--cui-spacings-mega);

    ${theme.mq.untilKilo} {
      padding: var(--cui-spacings-mega);
      justify-content: space-between;
    }
  `,
);

export const StyledModalWrapper = styled.div(
  ({ theme }) => css`
    padding-bottom: calc(var(--cui-spacings-mega) + ${CART_CONTAINER_HEIGHT});
    border-radius: var(--cui-border-radius-byte) var(--cui-border-radius-byte) 0
      0;

    ${theme.mq.kilo} {
      margin: 0 auto;
      padding-bottom: var(--cui-spacings-mega);
      border-radius: var(--cui-border-radius-byte);
    }
  `,
);

export const PriceContainer = styled.div(
  ({ theme }) => css`
    display: flex;
    align-items: center;
    flex-direction: row;

    ${theme.mq.kilo} {
      margin-right: var(--cui-spacings-mega);
    }
  `,
);

const RoundedIconButton = styled(IconButton)`
  border-radius: 50%;
  margin-right: var(--cui-spacings-kilo);
`;

export const NotificationIconButton = styled(RoundedIconButton)<{
  quantity?: number;
}>(
  ({ quantity }) => css`
    position: relative;
    overflow: visible;

    ${quantity &&
    `
      ::before {
      content: '${quantity}';
      color: var(--cui-fg-on-strong);
      width: ${NOTIFICATION_SIZE}px;
      height: ${NOTIFICATION_SIZE}px;
      background-color: var(--cui-bg-strong);
      position: absolute;
      border-radius: 50%;
      top: -${NOTIFICATION_OFFSET}px;
      right: -${NOTIFICATION_OFFSET}px;
    }
    `}
  `,
);

export const StyledShop = styled(Shop)(css`
  width: 16px;
  height: 16px;
`);
