import { Button } from '@sumup-oss/circuit-ui';
import { useState, type FC } from 'react';
import { useDispatch } from 'react-redux';

import type { Product } from 'productSelection/types/products';
import * as TestIds from 'shared/constants/TestIds';
import {
  dispatchBusinessAccountLearnMoreCloseEvent,
  dispatchBusinessAccountLearnMoreOpenEvent,
} from 'shared/services/tracker/events';
import { useTypedSelector } from 'shared/store';
import { selectBusinessAccount } from 'shared/store/order/actions';

import { BusinessAccountSelector } from '../BusinessAccountSelector';
import { CARD_OPTIONS } from '../BusinessAccountSelector/BusinessAccountSelector';
import { RichText } from '../RichText';
import { businessAccountModalOptions as richTextOptions } from '../RichText/configs/business-account';

import {
  ButtonWrapper,
  DescriptionWrapper,
  StyledDetails,
  StyledSummary,
  StyledWrapper,
} from './BusinessAccountModal.styles';

export interface Props {
  onProceedClick?: (isBusinessAccountSelected: boolean) => Promise<void>;
  product?: Product;
}

export const BusinessAccountModal: FC<Props> = ({
  onProceedClick,
  product,
}) => {
  const dispatch = useDispatch();
  const [isLearnMoreOpen, setIsLearnMoreOpen] = useState(false);
  const { isBusinessAccountSelected } = useTypedSelector(
    (state) => state.order,
  );
  const handleSelectOption = (selectedValue: string): void => {
    dispatch(
      selectBusinessAccount(selectedValue === CARD_OPTIONS.withBusinessAccount),
    );
  };

  const handleProceedClick = async (): Promise<void> => {
    await onProceedClick?.(isBusinessAccountSelected);
  };

  const handleLearnMoreClick = (): void => {
    if (!isLearnMoreOpen) {
      // when isLearnMoreOpen starts out false, that means this click
      // will open the details, thus we call the open event
      void dispatchBusinessAccountLearnMoreOpenEvent();
    } else {
      void dispatchBusinessAccountLearnMoreCloseEvent();
    }
    setIsLearnMoreOpen(!isLearnMoreOpen);
  };

  const { businessAccount, productSelector, transactionFee } = product;

  return (
    <StyledWrapper>
      <RichText
        richText={businessAccount.shortDescription}
        renderMark={richTextOptions.renderMark}
        renderNode={richTextOptions.renderNode}
      />
      <StyledDetails>
        <StyledSummary onClick={handleLearnMoreClick}>
          {businessAccount.descriptionCTA}
        </StyledSummary>
        <DescriptionWrapper>
          <RichText
            richText={businessAccount.description}
            renderMark={richTextOptions.renderMark}
            renderNode={richTextOptions.renderNode}
          />
        </DescriptionWrapper>
      </StyledDetails>
      <BusinessAccountSelector
        selectedCard={
          isBusinessAccountSelected
            ? CARD_OPTIONS.withBusinessAccount
            : CARD_OPTIONS.withoutBusinessAccount
        }
        setSelectedCard={handleSelectOption}
        productSelector={productSelector}
        transactionFee={transactionFee}
        businessAccountSelector={businessAccount.productSelector}
        businessAccountFee={businessAccount.transactionFee}
      />
      <ButtonWrapper>
        <Button
          data-testid={TestIds.ProductSelection.ProceedToCheckoutBA}
          variant="primary"
          onClick={handleProceedClick}
        >
          {businessAccount.selectProductCtaLabel}
        </Button>
      </ButtonWrapper>
    </StyledWrapper>
  );
};
