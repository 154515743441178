import { Select } from '@sumup-oss/circuit-ui';
import { type ChangeEvent, memo, useState } from 'react';

export interface Props extends ElementProps {
  currentlyAdded: number;
  quantityLimit?: number;
  onChange?: (value: number) => void;
}

type SelectOption = { label: string; value: number };

type ElementProps = { className?: string };

export const MAX_SELECTABLE_QUANTITY_NUMBER = 10;

const INITIAL_QUANTITY = 1;

const QuantitySelector = memo<Props>(
  ({ quantityLimit = MAX_SELECTABLE_QUANTITY_NUMBER, onChange, className }) => {
    const [value, setValue] = useState(INITIAL_QUANTITY);

    const options: SelectOption[] = new Array(quantityLimit)
      .fill(0)
      .map((_, i) => ({
        label: String(i + 1),
        value: i + 1,
      }));

    const handleChange = (e: ChangeEvent<HTMLSelectElement>): void => {
      const selectedValue = Number(e.target.value);
      setValue(selectedValue);
      onChange(selectedValue);
    };

    const isDisabled = quantityLimit === 0;

    return (
      <Select
        data-testid="quantity-selector"
        label="quantity"
        hideLabel
        name="product-details--quantity-selection"
        aria-label="Change product quantity"
        className={className}
        value={value}
        onChange={handleChange}
        options={options}
        disabled={isDisabled}
        css={{ marginBottom: 'var(--cui-spacings-mega)' }}
      />
    );
  },
);

export default QuantitySelector;
