import { BLOCKS } from '@contentful/rich-text-types';
import { Headline } from '@sumup-oss/circuit-ui';

import { defaultRenderOptions } from './richTextOptions';

export const businessAccountModalOptions = {
  ...defaultRenderOptions,
  renderNode: {
    ...defaultRenderOptions.renderNode,
    [BLOCKS.HEADING_3]: (_, children) => (
      <Headline
        size="s"
        as="h3"
        css={{ marginBottom: 'var(--cui-spacings-byte)' }}
      >
        {children}
      </Headline>
    ),
  },
};
