import styled from '@emotion/styled';
import { Headline } from '@sumup-oss/circuit-ui';

export const CardWrapper = styled.ul`
  list-style: none;
  display: flex;
  gap: var(--cui-spacings-giga);
  margin: var(--cui-spacings-giga) 0;
  overflow-x: auto;

  &::-webkit-scrollbar {
    display: none;
  }
`;

export const StyledListItem = styled.li`
  margin: 1px;
`;

export const StyledRadioInput = styled.input`
  position: absolute;
  margin: 0 !important;
  padding: 0 !important;
  opacity: 0;
  height: 0;
  width: 0;
  pointer-events: none;

  &:checked + label {
    border: var(--cui-border-width-mega) solid #3064e3;
  }

  &:focus + label {
    outline: var(--cui-border-width-kilo) solid #3064e3;
  }
`;

export const Card = styled.label`
  display: block;
  width: 220px;
  border: var(--cui-border-width-mega) solid var(--cui-border-normal);
  border-radius: var(--cui-border-radius-mega);
  padding: var(--cui-spacings-kilo);
  cursor: pointer;
  height: 100%;
`;

export const CardHeadline = styled(Headline)`
  font-size: var(--cui-typography-body-one-font-size);
`;

export const BadgeWrapper = styled.div`
  margin-top: var(--cui-spacings-byte);
  margin-bottom: var(--cui-spacings-mega);
  height: 24px;
`;

export const FeeWrapper = styled.p`
  font-size: var(--cui-typography-headline-two-font-size);
  line-height: var(--cui-typography-headline-two-line-height);
  margin: var(--cui-spacings-byte) 0;
`;

export const OldFee = styled.span`
  color: var(--cui-fg-subtle-hovered);
  text-decoration: line-through;
`;

export const NewFee = styled.span`
  color: var(--cui-border-success);
  font-weight: var(--cui-font-weight-bold);
  margin-left: var(--cui-spacings-byte);
`;

export const CurrentFee = styled.span`
  color: var(--cui-fg-subtle-hovered);
  font-weight: var(--cui-font-weight-bold);
`;

export const BenefitGrid = styled.div`
  display: grid;
  grid-template-columns: var(--cui-spacings-mega) 1fr;
  grid-column-gap: var(--cui-spacings-byte);
  grid-row-gap: var(--cui-spacings-mega);
  color: var(--cui-fg-subtle);
  margin-top: var(--cui-spacings-giga);

  & ~ div {
    margin-top: var(--cui-spacings-mega);
  }
`;

export const BenefitIcon = styled.div`
  position: relative;
  margin: 0 auto;
  overflow: hidden;
  width: 16px;
  height: 16px;
`;
